import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const ThankPage = () => {
    return (
        <div className="main-page-wrapper p0">
            <Helmet>
                <title>Дякуємо за звернення || CRM Genesis</title>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            {/* End Page SEO Content */}

            <div className="error-page d-lg-flex align-items-center">
                <div className="img-holder order-lg-last">
                    <img
                        src="/images/icon/02.svg"
                        alt="media"
                        className="w-100 illustration"
                    />
                </div>
                <div className="text-wrapper order-lg-first">
                    <h1 className="font-slab">
                        Дякуємо за звернення
                    </h1>
                    <p className="font-rubik">
                    </p>

                    <Link
                        to="/"
                        className="back-home font-rubik d-flex align-items-center justify-content-center"
                    >
                        <span>На головну</span>
                        <img src="/images/icon/53.svg" alt="icon" />
                    </Link>
                </div>
                {/* /.text-wrapper */}
            </div>
            {/* /.error-page */}
        </div>
    );
};
export default ThankPage;
