import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import HeaderPopupForm from "../../form/HeaderPopupForm";
import Modal from "react-modal";
import {useHistory} from "react-router-dom";

const ContactForm = () => {
    // for validation
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("Імʼя обовʼязвове для заповнення"),
        phone: Yup.string().required("Телефон обовʼязвовий для заповнення"),
        email: Yup.string().required("E-mail обовʼязвовий для заповнення"),
        sendMessage: Yup.string().required("Будь ласка напишіть ваше повідомлення"),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};
    // get functions to build form with useForm() hook
    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors} = formState;
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    function toggleModalOne() {
        setIsOpen(!isOpen);
    }

    function onSubmit(data, e) {
        // display form data on success
        e.preventDefault();

        const apiUrl = "https://crmgenesis.creatio.com/0/ServiceModel/GeneratedObjectWebFormService.svc/SaveWebFormObjectData";

        const requestBody = {
            formData: {
                formId: "52656db1-11a4-4a43-8b8a-d1c35b7908b3",
                formFieldsData: [
                    {name: "Name", value: data.firstName + ' ' + data.lastName},
                    {name: "UserEmail", value: data.email},
                    {name: "Email", value: data.email},
                    {name: "MobilePhone", value: data.phone},
                    {name: "Commentary", value: data.sendMessage},
                    {name: "BpmHref", value: window.location.href},
                ],
                contactFieldsData: [
                    {name: "FullName", value: data.firstName},
                    {name: "Phone", value: data.phone},
                    {name: "Email", value: data.email},
                ],
                options: {extendResponseWithExceptionType: true},
            },
        };

        const bpmTrackingId = getCookie("bpmTrackingId");

        fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "bpmTrackingId": bpmTrackingId,
            },
            body: JSON.stringify(requestBody),
        })
            .then((data) => {
                console.log("Response data:", data);
                toggleModalOne();

                window.gtag('event', 'fill_contact_form', {
                    'block_number': 'block_contacts',
                    'button_name': 'submit',
                    'formURL': 'https://crmgenesis.com/contact'
                });
                window.dataLayer.push({'event': 'send_form'});
                history.push('/thanks/');
            })
            .catch((error) => {
                console.error("Error:", error);
                // Обработка ошибки при выполнении запроса
            });
        e.target.reset();
    }

    useEffect(() => {

    }, []);

    const getCookie = (name) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    };

    return (
        <>
            <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="messages"></div>
                <div className="row controls">
                    <div className="col-md-6">
                        <div className="input-group-meta form-group mb-60">
                            <label>Імʼя</label>
                            <input
                                type="text"
                                placeholder="Імʼя"
                                name="firstName"
                                {...register("firstName")}
                                className={`${errors.firstName ? "is-invalid" : ""}`}
                            />
                            {errors.firstName && (
                                <div className="invalid-feedback">
                                    {errors.firstName?.message}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* End .col */}

                    <div className="col-md-6">
                        <div className="input-group-meta form-group mb-60">
                            <label>Прізвище</label>
                            <input
                                type="text"
                                placeholder="Прізвище"
                                name="lastName"
                                {...register("lastName")}
                                className={`${errors.lastName ? "is-invalid" : ""}`}
                            />
                            {errors.lastName && (
                                <div className="invalid-feedback">{errors.lastName?.message}</div>
                            )}
                        </div>
                    </div>
                    {/* End .col */}
                    <div className="col-md-6">
                        <div className="input-group-meta form-group mb-60">
                            <label>Телефон</label>
                            <input
                                placeholder="Телефон"
                                name="email"
                                type="text"
                                {...register("phone")}
                                className={` ${errors.phone ? "is-invalid" : ""}`}
                            />
                            {errors.phone && (
                                <div className="invalid-feedback">{errors.phone?.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group-meta form-group mb-60">
                            <label>Email</label>
                            <input
                                placeholder="Email адреса"
                                name="email"
                                type="text"
                                {...register("email")}
                                className={` ${errors.email ? "is-invalid" : ""}`}
                            />
                            {errors.email && (
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            )}
                        </div>
                    </div>
                    {/* End .col */}

                    <div className="col-12">
                        <div className="input-group-meta form-group lg mb-40">
                            <label>Повідомлення</label>
                            <textarea
                                placeholder="Опишіть свій запит"
                                name="sendMessage"
                                {...register("sendMessage")}
                                className={`${errors.sendMessage ? "is-invalid" : ""}`}
                            ></textarea>
                            {errors.sendMessage && (
                                <div className="invalid-feedback">
                                    {errors.sendMessage?.message}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* End .col */}

                    <div className="col-12">
                        <button type="submit" className="theme-btn-two">
                            Відправити
                        </button>
                    </div>
                    {/* End .col */}
                </div>
            </form>

            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModalOne}
                contentLabel="My dialog"
                className="custom-modal  modal-contact-popup-one w-250"
                style={{width: '250px'}}
                overlayClassName="custom-overlay"
                closeTimeoutMS={500}
            >
                <div className="">
                    <main className="main-body box_inner modal-content clearfix">
                        <button className="close" onClick={toggleModalOne}>
                            <img src="images/icon/close.svg" alt="close"/>
                        </button>
                        {/* End close icon */}

                        <div className="d-flex flex-column justify-content-between align-items-center h-100 p-5 text-center">
                            <img src="images/icon/19.svg" alt="close" style={{height: '50px', marginBottom: 20}}/>
                            <p>Ваша заявка відправлена. Дякуємо за ваш запит!  Наші фахівці зв'яжуться з вами найближчим часом, щоб обговорити деталі і надати необхідну інформацію.</p>

                        </div>
                        {/* /.left-side */}
                    </main>
                    {/* /.main-body */}
                </div>
            </Modal>
        </>

    );
};

export default ContactForm;
